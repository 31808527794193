import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Seo from "../components/seo";

const teamPic = {
  position: 'relative',
  top: '0',
  botom: '0',
  margin: 'auto'
}

const Galerie = () => {
  return (
      <Layout>
        <Seo title="Galerie der Naturspielgruppe Wakiti in Uster" />
        <div className="content">
          <div className='title'><h1>Galerie</h1></div>
          <Container>
            <Row>
              
              <Col md style={teamPic}>
                <div>
                <StaticImage
                  src="../images/galerie/ziegen_am_essen.jpg"
                  //width={300}
                  // quality={95}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="hof"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md style={teamPic}>
              <div>
                <StaticImage
                  src="../images/galerie/kind.jpg"
                  //width={300}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="hof"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md style={teamPic}>
                <div>
                <StaticImage
                  src="../images/galerie/kind1.jpg"
                  //width={300}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="ziege1"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
            </Row>
            <Row>
            <Col md style={teamPic}>
                <div>
                <StaticImage
                  src="../images/galerie/garten2.jpg"
                  //width={300}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="garten2"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md style={teamPic}>
                <div>
                <StaticImage
                  src="../images/galerie/hof.jpg"
                  //width={300}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="garten2"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
              <Col md style={teamPic}>
                <div>
                <StaticImage
                  src="../images/galerie/garten1.jpg"
                  //width={300}
                  quality={50}
                  formats={["auto", "webp", "avif"]}
                  alt="garten2"
                  style={{ marginBottom: `1.45rem` }}
                />
                </div>
              </Col>
            </Row>
            
          </Container>
        </div>
      </Layout>
  )
}

export default Galerie;